'use client';

import { Metadata } from 'next';
import * as React from 'react';
import { BiHome } from 'react-icons/bi';
import { FaInstagram } from 'react-icons/fa';

import ButtonLink from '@/components/links/ButtonLink';
import NextImage from '@/components/NextImage';
import Typography from '@/components/Typography';

export const metadata: Metadata = {
  title: '500',
};

export default function InternalServerError() {
  return (
    <main className='flex min-h-screen w-full items-center justify-center overflow-hidden bg-typo-main'>
      <section className='max-h-3/5 max-w-4/5 flex flex-col items-center justify-center px-6'>
        <div className='relative max-md:px-4 max-sm:px-0 md:max-w-full md:px-1'>
          <NextImage
            src='/500/500.png'
            alt='500'
            width={543}
            height={298}
            className='relative z-20 max-lg:w-[480px] max-md:w-[410px] max-sm:w-[230px]'
            imgClassName='mx-auto'
          />
          <NextImage
            src='/500/Kartu.png'
            alt='500'
            width={252}
            height={237}
            className='absolute z-10 max-lg:-right-24 max-lg:-top-16 max-lg:w-[220px] max-md:-right-20 max-md:-top-14 max-md:w-[190px] max-sm:-right-12 max-sm:-top-8 max-sm:w-[110px] lg:-right-28 lg:-top-20 lg:mt-2'
          />
        </div>
        <Typography
          as='h4'
          variant='h4'
          weight='bold'
          font='Poppins'
          className='mt-4 bg-clip-text text-center text-transparent max-md:text-4xl max-sm:text-2xl md:mt-8 md:text-5xl lg:text-6xl'
          style={{
            background:
              'linear-gradient(to right, #3BD09A 0%, #54C4DB 25%, #FC3E3E 60%, #FFCF55 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Internal Server Error
        </Typography>
        <Typography
          as='p'
          variant='p'
          font='Poppins'
          className='mt-2 max-w-2xl text-center text-sm text-neutral-100'
        >
          Maaf, sepertinya server tidak dapat memproses permintaan kamu. Coba
          refresh halaman atau kamu dapat menghubungi kami untuk masalah ini.
        </Typography>
        <div className='mt-8 flex flex-col items-center justify-center gap-4 md:mt-10 md:w-full md:flex-row'>
          <ButtonLink
            href='/'
            variant='outline'
            className='w-full text-neutral-100 md:w-fit'
            size='md'
            icon={BiHome}
          >
            Homepage
          </ButtonLink>
          <ButtonLink
            href='https://instagram.com/schematics.its'
            variant='outline'
            size='md'
            className='w-full text-center text-neutral-100 md:w-fit'
            icon={FaInstagram}
          >
            Instagram Schematics 2024
          </ButtonLink>
        </div>
      </section>
    </main>
  );
}
